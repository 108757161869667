<template>
  <main class="main">
    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'MainComponent'
}
</script>